@import 'assets/scss/main';

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.rpv-toolbar {
	background-color: #e6f0f7;
	padding-block: 0.5rem;
}
