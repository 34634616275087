
.download-percent-info {

  @include max-screen($sm-max) {
      margin-bottom: 40px;
  }

  &__percent {
    font-size: 50px;
    color: $key-color-5;
    font-weight: $default-font-weight-bold;
    margin-bottom: 30px;
    line-height: 1.1;
  }


  &__text {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    i {
      font-size: 19px;
      color: $key-color-1;
      margin-right: 14px;
    }

    span.text {
      font-size: 13px;
      color: $black-color;
    }

  }
}


.your-data {


}


.hidden-invoice-container {
  margin-top: 25px;
}

.input-wrapper-vat {
  display: flex;

  .form__field:first-child {
    width: 100%;
  }

  .form__field:last-child {
    margin-left: 16px;
    max-width: 118px;
  }



  @include max-screen($sm-max) {
    margin-bottom: 13px;
    width: 100%;
    margin-right: 0;
  }



  .input-container {
    position: relative;

    > input {

      z-index: 1;
    }

    .floating-icon {
      top: 17px;
      right: 23px;
      font-size: 20px;
      color: #93B8D5;
      position: absolute;
      z-index: 3;

      //.ico-tooltip span.path2::before {
      //  margin-left: -30px;
      //}
    }
  }

}



/* PODSUMOWANIE */

.center-container {
  padding: 50px 0;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thank-you-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  letter-spacing: -1.3px;
  font-weight: $default-font-weight-bold;
  color: $default-font-color;
  margin-bottom: 35px;
  text-align: center;

  @include max-screen($xxs-max) {
      font-size: 20px;
  }

  i {
    margin-right: 20px;
    color: #6BAD5F;
  }
}

.thank-you-subtitle {
  font-size: 16px;
  color: $black-color;
  margin-bottom: 50px;
  text-align: center;
}

